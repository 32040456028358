* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 2650px;
    height: 600px;
  }
  
  #zoomtext {
    width: 2100px;
    /* background-color: #808080; */
    padding-bottom: 50px;
    /* height: 600px; */
  }
  
  /* @media screen and (max-width: 767px) {
    #zoomtext {
      height: 400px;
    }
  }
   */
  .zoom-container {
    position: relative;
    overflow: scroll;
  }
  
  .circle-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 51%;
    /* background: #143a66; */
    background: linear-gradient(135deg, #157D74 0%, #00A9A9 100%);
    position: relative;
    margin: 37px 34px;
    counter-increment: myIndex;
    /* margin-top: 50px; */
  }
  
  .circle-wrapper::before {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 2.5px;
  }
  .circle-wrapper.mesa_1::before {
    content: "1";
  }
  .circle-wrapper.mesa_2::before {
    content: "2";
  }
  .circle-wrapper.mesa_3::before {
    content: "3";
  }
  .circle-wrapper.mesa_4::before {
    content: "4";
  }
  .circle-wrapper.mesa_5::before {
    content: "5";
  }
  .circle-wrapper.mesa_6::before {
    content: "6";
  }
  .circle-wrapper.mesa_7::before {
    content: "7";
  }
  .circle-wrapper.mesa_8::before {
    content: "8";
  }
  .circle-wrapper.mesa_9::before {
    content: "9";
  }
  .circle-wrapper.mesa_10::before {
    content: "10";
  }
  .circle-wrapper.mesa_11::before {
    content: "11";
  }
  .circle-wrapper.mesa_12::before {
    content: "12";
  }
  .circle-wrapper.mesa_13::before {
    content: "13";
  }
  .circle-wrapper.mesa_14::before {
    content: "14";
  }
  .circle-wrapper.mesa_15::before {
    content: "15";
  }
  .circle-wrapper.mesa_16::before {
    content: "16";
  }
  .circle-wrapper.mesa_17::before {
    content: "17";
  }
  .circle-wrapper.mesa_18::before {
    content: "18";
  }
  .circle-wrapper.mesa_19::before {
    content: "19";
  }
  .circle-wrapper.mesa_20::before {
    content: "20";
  }
  .circle-wrapper.mesa_21::before {
    content: "21";
  }
  .circle-wrapper.mesa_22::before {
    content: "22";
  }
  .circle-wrapper.mesa_23::before {
    content: "23";
  }
  .circle-wrapper.mesa_24::before {
    content: "24";
  }
  .circle-wrapper.mesa_25::before {
    content: "25";
  }
  .circle-wrapper.mesa_26::before {
    content: "26";
  }
  .circle-wrapper.mesa_27::before {
    content: "27";
  }
  .circle-wrapper.mesa_28::before {
    content: "28";
  }
  .circle-wrapper.mesa_29::before {
    content: "29";
  }
  .circle-wrapper.mesa_30::before {
    content: "30";
  }
  .circle-wrapper.mesa_31::before {
    content: "31";
  }
  .circle-wrapper.mesa_32::before {
    content: "32";
  }
  .circle-wrapper.mesa_33::before {
    content: "33";
  }
  .circle-wrapper.mesa_34::before {
    content: "34";
  }
  .circle-wrapper.mesa_35::before {
    content: "35";
  }
  .circle-wrapper.mesa_36::before {
    content: "36";
  }
  .circle-wrapper.mesa_37::before {
    content: "37";
  }
  .circle-wrapper.mesa_38::before {
    content: "38";
  }
  .circle-wrapper.mesa_39::before {
    content: "39";
  }
  .circle-wrapper.mesa_40::before {
    content: "40";
  }
  .circle-wrapper.mesa_41::before {
    content: "41";
  }
  .circle-wrapper.mesa_42::before {
    content: "42";
  }
  .circle-wrapper.mesa_43::before {
    content: "43";
  }
  .circle-wrapper.mesa_44::before {
    content: "44";
  }
  .circle-wrapper.mesa_45::before {
    content: "45";
  }
  .circle-wrapper.mesa_46::before {
    content: "46";
  }
  .circle-wrapper.mesa_47::before {
    content: "47";
  }
  .circle-wrapper.mesa_48::before {
    content: "48";
  }
  .circle-wrapper.mesa_49::before {
    content: "49";
  }
  .circle-wrapper.mesa_50::before {
    content: "50";
  }
  .circle-wrapper.mesa_51::before {
    content: "51";
  }
  .circle-wrapper.mesa_52::before {
    content: "52";
  }
  .circle-wrapper.mesa_53::before {
    content: "53";
  }
  .circle-wrapper.mesa_54::before {
    content: "54";
  }
  .circle-wrapper.mesa_55::before {
    content: "55";
  }
  .circle-wrapper.mesa_56::before {
    content: "56";
  }
  .circle-wrapper.mesa_57::before {
    content: "57";
  }
  .circle-wrapper.mesa_58::before {
    content: "58";
  }
  .circle-wrapper.mesa_59::before {
    content: "59";
  }
  .circle-wrapper.mesa_60::before {
    content: "60";
  }
  .circle-wrapper.mesa_61::before {
    content: "61";
  }
  .circle-wrapper.mesa_62::before {
    content: "62";
  }
  .circle-wrapper.mesa_63::before {
    content: "63";
  }
  .circle-wrapper.mesa_64::before {
    content: "64";
  }
  .circle-wrapper.mesa_65::before {
    content: "65";
  }
  .circle-wrapper.mesa_66::before {
    content: "66";
  }
  .circle-wrapper.mesa_67::before {
    content: "67";
  }
  .circle-wrapper.mesa_68::before {
    content: "68";
  }
  .circle-wrapper.mesa_69::before {
    content: "69";
  }
  .circle-wrapper.mesa_70::before {
    content: "70";
  }
  .circle-wrapper.mesa_71::before {
    content: "71";
  }
  .circle-wrapper.mesa_72::before {
    content: "72";
  }
  .circle-wrapper.mesa_73::before {
    content: "73";
  }
  .circle-wrapper.mesa_74::before {
    content: "74";
  }
  .circle-wrapper.mesa_75::before {
    content: "75";
  }
  .circle-wrapper.mesa_76::before {
    content: "76";
  }
  .circle-wrapper.mesa_77::before {
    content: "77";
  }
  .circle-wrapper.mesa_78::before {
    content: "78";
  }
  .circle-wrapper.mesa_79::before {
    content: "79";
  }
  .circle-wrapper.mesa_80::before {
    content: "80";
  }
  .circle-wrapper.mesa_81::before {
    content: "81";
  }
  
  .circle-wrapper::before.disabled {
    color: #000000;
  }
  
  .circle-wrapper.disabled {
    background: #808080;
  }
  
  .circle {
    display: block;
    position: absolute;
    top: 98%;
    left: 97%;
    width: 20px;
    height: 20px;
    margin: -36px;
    background: transparent;
    border-radius: 51%;
    text-align: center;
    line-height: 60px;
    z-index: 99;
  }
  
  /* Diez */
  
  .diez .deg-1 {
    /* transform: translate(0px); */
    transform: translate(0px, -42px);
  }
  .diez .deg-1 .checkbox::before {
    rotate: 180deg;
  }
  .diez .deg-2 {
    transform: translate(23px, -34px);
    /* transform: rotate(37deg) translate(35px) rotate(-37deg); */
  }
  
  .diez .deg-3 {
    transform: translate(39px, -14px);
    /* transform: rotate(73deg) translate(35px) rotate(-73deg); */
  }
  
  .diez .deg-4 {
    transform: translate(39px, 12px);
    /* transform: rotate(109deg) translate(35px) rotate(-109deg); */
  }
  
  .diez .deg-5 {
    transform: translate(24px, 32px);
    /* transform: rotate(145deg) translate(35px) rotate(-145deg); */
  }
  
  .diez .deg-6 {
    transform: translate(0px, 42px);
    /* transform: rotate(181deg) translate(35px) rotate(-181deg); */
  }
  
  .diez .deg-7 {
    transform: translate(-24px, 32px);
    /* transform: rotate(217deg) translate(35px) rotate(-217deg); */
  }
  
  .diez .deg-8 {
    transform: translate(-39px, 12px);
    /* transform: rotate(253deg) translate(35px) rotate(-253deg); */
  }
  
  .diez .deg-9 {
    transform: translate(-39px, -14px);
    /* transform: rotate(289deg) translate(35px) rotate(-289deg); */
  }
  
  .diez .deg-10 {
    transform: translate(-23px, -34px);
    /* transform: rotate(325deg) translate(35px) rotate(-325deg); */
  }
  
  /* Diez */
  
  /* Once */
  
  .once .deg-1 {
    transform: translate(35px);
  }
  
  .once .deg-2 {
    transform: rotate(33.72deg) translate(35px) rotate(-33.72deg);
  }
  
  .once .deg-3 {
    transform: rotate(66.44deg) translate(35px) rotate(-66.44deg);
  }
  
  .once .deg-4 {
    transform: rotate(99.16deg) translate(35px) rotate(-99.16deg);
  }
  
  .once .deg-5 {
    transform: rotate(131.88deg) translate(35px) rotate(-131.88deg);
  }
  
  .once .deg-6 {
    transform: rotate(164.6deg) translate(35px) rotate(-164.6deg);
  }
  
  .once .deg-7 {
    transform: rotate(197.32deg) translate(35px) rotate(-197.32deg);
  }
  
  .once .deg-8 {
    transform: rotate(230.04deg) translate(35px) rotate(-230.04deg);
  }
  
  .once .deg-9 {
    transform: rotate(262.76deg) translate(35px) rotate(-262.76deg);
  }
  
  .once .deg-10 {
    transform: rotate(295.48deg) translate(35px) rotate(-295.48deg);
  }
  
  .once .deg-11 {
    transform: rotate(328.2deg) translate(35px) rotate(-328.2deg);
  }
  
  /* Once */
  
  /* Doce */
  
  .doce .deg-1 {
    transform: translate(35px);
  }
  
  .doce .deg-2 {
    transform: rotate(31deg) translate(35px) rotate(-31deg);
  }
  
  .doce .deg-3 {
    transform: rotate(61deg) translate(35px) rotate(-61deg);
  }
  
  .doce .deg-4 {
    transform: rotate(91deg) translate(35px) rotate(-91deg);
  }
  
  .doce .deg-5 {
    transform: rotate(121deg) translate(35px) rotate(-121deg);
  }
  
  .doce .deg-6 {
    transform: rotate(151deg) translate(35px) rotate(-151deg);
  }
  
  .doce .deg-7 {
    transform: rotate(181deg) translate(35px) rotate(-181deg);
  }
  
  .doce .deg-8 {
    transform: rotate(211deg) translate(35px) rotate(-211deg);
  }
  
  .doce .deg-9 {
    transform: rotate(241deg) translate(35px) rotate(-241deg);
  }
  
  .doce .deg-10 {
    transform: rotate(271deg) translate(35px) rotate(-271deg);
  }
  
  .doce .deg-11 {
    transform: rotate(301deg) translate(35px) rotate(-301deg);
  }
  
  .doce .deg-12 {
    transform: rotate(331deg) translate(35px) rotate(-331deg);
  }
  
  /* Doce */
  
  /* Nueve */
  
  .nueve .deg-1 {
    transform: translate(35px);
  }
  
  .nueve .deg-2 {
    transform: rotate(41deg) translate(35px) rotate(-41deg);
  }
  
  .nueve .deg-3 {
    transform: rotate(81deg) translate(35px) rotate(-81deg);
  }
  
  .nueve .deg-4 {
    transform: rotate(121deg) translate(35px) rotate(-121deg);
  }
  
  .nueve .deg-5 {
    transform: rotate(161deg) translate(35px) rotate(-161deg);
  }
  
  .nueve .deg-6 {
    transform: rotate(201deg) translate(35px) rotate(-201deg);
  }
  
  .nueve .deg-7 {
    transform: rotate(241deg) translate(35px) rotate(-241deg);
  }
  
  .nueve .deg-8 {
    transform: rotate(281deg) translate(35px) rotate(-281deg);
  }
  
  .nueve .deg-9 {
    transform: rotate(321deg) translate(35px) rotate(-321deg);
  }
  
  /* Nueve */
  
  /* Ocho */
  
  .ocho .deg-1 {
    transform: translate(35px);
  }
  
  .ocho .deg-2 {
    transform: rotate(46deg) translate(35px) rotate(-46deg);
  }
  
  .ocho .deg-3 {
    transform: rotate(91deg) translate(35px) rotate(-91deg);
  }
  
  .ocho .deg-4 {
    transform: rotate(136deg) translate(35px) rotate(-136deg);
  }
  
  .ocho .deg-5 {
    transform: rotate(181deg) translate(35px) rotate(-181deg);
  }
  
  .ocho .deg-6 {
    transform: rotate(226deg) translate(35px) rotate(-226deg);
  }
  
  .ocho .deg-7 {
    transform: rotate(271deg) translate(35px) rotate(-271deg);
  }
  
  .ocho .deg-8 {
    transform: rotate(316deg) translate(35px) rotate(-316deg);
  }
  
  /* Ocho */
  
  .checkbox {
    /* background: #B3B3B3; */
    display: flex;
    padding: 12px;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
  }

  .checkbox::before {
    background-repeat: no-repeat;
    background-size: 24px 19px;
    width: 24px;
    height: 19px;
    content: '';
    background-image: url(https://demo.prointernet.website/cumbreoaxaca/assets/img/seatdisponible.png);
    display: flex;
    position: absolute;
  }
  
  .checkbox.disabled {
    background: #bebebe;
  }
  /* .checkbox.ocupado {
    background: #FF1AB7 ;
  } */
  .checkbox.ocupado::before {
    background-repeat: no-repeat;
    background-size: 24px 19px;
    width: 24px;
    height: 19px;
    content: '';
    background-image: url(https://demo.prointernet.website/cumbreoaxaca/assets/img/seatocupado.png);
    display: flex;
    position: absolute;
  }
  /* .checkbox.reservado {
    background: #3c3c3c;
  } */
  .checkbox.reservado::before {
    background-repeat: no-repeat;
    background-size: 24px 19px;
    width: 24px;
    height: 19px;
    content: '';
    background-image: url(https://demo.prointernet.website/cumbreoaxaca/assets/img/seatdeshabilitado.png);
    display: flex;
    position: absolute;
  }
  /* .checkbox.active {
    background:#00A9A9;
  } */
  .checkbox.active::before {
    background-repeat: no-repeat;
    background-size: 24px 19px;
    width: 24px;
    height: 19px;
    content: '';
    background-image: url(https://demo.prointernet.website/cumbreoaxaca/assets/img/seatseleccionado.png);
    display: flex;
    position: absolute;
  }
  
  .checkbox-element {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
  
  .checkbox > input[type="checkbox"] + i {
    color: rgba(255, 255, 255, 0.2);
  }
  
  .checkbox > input[type="checkbox"]:checked + i {
    color: #fff;
  }
  
  .checkbox > input[type="checkbox"]:disabled {
    pointer-events: none;
  }
  
  .escenario {
    height: 200px;
    background: #143a66;
    width: 420px;
    margin: auto;
    position: relative;
    margin-bottom: 70px;
    border-radius: 6px;
  }
  
  .escenario::before {
    content: "Escenario";
    color: white;
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .escenario-col {
    height: 100px;
    background: black;
    width: 80px;
    margin-top: -82px;
  }

  .escenario2 {
    height: 200px;
    /* background: #143a66; */
    background: linear-gradient(91deg, #127067 0%, #00A9A9 100%);
    width: 530px;
    margin: auto;
    position: relative;
    margin-bottom: 70px;
    border-radius: 6px;
  }
  
  .escenario2::before {
    content: "Escenario";
    color: white;
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
  }
  
  .escenario2-col {
    height: 100px;
    background: black;
    width: 80px;
    margin-top: -82px;
  }
  
  .table-style {
    box-shadow: 0px 2px 48px rgb(150 170 180 / 28%);
    padding: 15px;
    border-radius: 10px;
  }
  
  #zoomIn,
  #zoomOut {
    padding: 6px;
    border: none;
    background: #143a66;
    border-radius: 4px;
    color: #fff;
    box-shadow: 0px 2px 48px rgb(150 170 180 / 28%);
    width: 40px;
    height: 40px;
  }
  
  .zooms {
    position: absolute;
    right: 36px;
    top: 0;
    z-index: 9;
  }
  
  .green-text{
    color: #00bf8b;
  }
  .green{
    background: #00bf8b;
    height: 10px;
    width: 10px;
  }
  .red{
    background: #F64E4E;
    height: 10px;
    width: 10px;
  }
  .gray{
    background: #bebebe;
    height: 10px;
    width: 10px;
  }
  .purple{
    background: purple;
    height: 10px;
    width: 10px;
  }
  .yellow{
    background: rgb(234, 223, 16);;
    height: 10px;
    width: 10px;
  }
  .asientos {
    display: flex;
    align-items: center;
  }
  
  .asientos-body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .asientos span{
  font-size: 13px;
  margin-left: 10px;
  }
  
  .asientos-wrapper{
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 48px rgb(150 170 180 / 28%);
    margin-top: 15px;
  }
  
  .columna {
    height: 50px;
    width: 50px;
    margin: 30px;
    background: #27736b;
  }
  
  input[type="checkbox"] {
    height: 100% !important;
    width: 100% !important;
  }

  .__react_component_tooltip {
    line-height: 15px;
    width: 200px;
    text-align: center;
  }

  .arco1 {
    height: 50px;
      width: 50px;
      background: #27736b;
     /*  border-top: 10px solid #27736b;
      border-bottom: 10px solid #27736b;
      border-left: 10px solid #27736b;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px; */
      margin: 25px;
  }
  .arco2 {
    height: 50px;
      width: 50px;
      background: #27736b;
      /* border-top: 10px solid #27736b;
      border-bottom: 10px solid #27736b;
      border-right: 10px solid #27736b;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px; */
      margin: 25px;
  }

  .titulos_asientos {
    padding-top: 40px;
  }
  .cena1_cont, .cena2_cont {
    background-color: #143a66;
    border-radius: 6px;
    color: #fff;
    padding: 20px;
    width: 100%;
    opacity: .5;
    transition: all .5s ease-in-out;
  }
  .cena1_cont.active, .cena2_cont.active,
  .cena1_cont.hover, .cena2_cont.hover {
    opacity: 1;
  }
  .cena1_cont h3, .cena2_cont h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 700;
  }


  /* MESA CIRCULAR ASIENTOS */
  .mesa-cuadrada.circle-wrapper {
    width: 70px;
    height: 60px;
    margin: 40px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .mesa-cuadrada.circle-wrapper .circle {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 24px;
    height: 19px;
  }
  .mesa-cuadrada.circle-wrapper .circle .checkbox {
    width: 24px;
    height: 19px;
    padding: 0;
  }

  .mesa-cuadrada.circle-wrapper .deg-1 {
    transform: translate(26px, 44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-1 .checkbox::before {
    rotate: 0deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-1:hover {
    z-index: 10000!important;
  }

  .mesa-cuadrada.circle-wrapper .deg-2 {
    transform: translate(0px, 44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-2 .checkbox::before {
    rotate: 0deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-2:hover {
    z-index: 10000!important;
  }

  .mesa-cuadrada.circle-wrapper .deg-3 {
    transform: translate(-26px, 44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-3 .checkbox::before {
    rotate: 0deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-3:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-4 {
    transform: translate(-48px, 12px);
  }
  .mesa-cuadrada.circle-wrapper .deg-4 .checkbox::before {
    rotate: 90deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-4:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-5 {
    transform: translate(-48px, -14px);
  }
  .mesa-cuadrada.circle-wrapper .deg-5 .checkbox::before {
    rotate: 90deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-5:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-6 {
    transform: translate(-26px, -44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-6 .checkbox::before {
    rotate: 180deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-6:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-7 {
    transform: translate(0px, -44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-7 .checkbox::before {
    rotate: 180deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-7:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-8 {
    transform: translate(26px, -44px);
  }
  .mesa-cuadrada.circle-wrapper .deg-8 .checkbox::before {
    rotate: 180deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-8:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-9 {
    transform: translate(48px, -14px);
  }
  .mesa-cuadrada.circle-wrapper .deg-9 .checkbox::before {
    rotate: -90deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-9:hover {
    z-index: 10000!important;
  }
  .mesa-cuadrada.circle-wrapper .deg-10 {
    transform: translate(48px, 12px);
  }
  .mesa-cuadrada.circle-wrapper .deg-10 .checkbox::before {
    rotate: -90deg;
  }
  .mesa-cuadrada.circle-wrapper .deg-10:hover {
    z-index: 10000!important;
  }


  /* MESA CIRCULAR ASIENTOS */
  .mesa-circular.circle-wrapper {
    width: 60px;
    height: 60px;
    margin: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mesa-circular.circle-wrapper .circle {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 24px;
    height: 19px;
  }
  .mesa-circular.circle-wrapper .circle .checkbox {
    width: 24px;
    height: 19px;
    padding: 0;
  }
  .mesa-circular.circle-wrapper .deg-1 {
    transform: translate(0px, 48px);
  }
  .mesa-circular.circle-wrapper .deg-1 .checkbox::before {
    rotate: 0deg;
  }
  .mesa-circular.circle-wrapper .deg-1:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-2 {
    transform: translate(-27px, 38px);
  }
  .mesa-circular.circle-wrapper .deg-2 .checkbox::before {
    rotate: 40deg;
  }
  .mesa-circular.circle-wrapper .deg-2:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-3 {
    transform: translate(-43px, 15px);
  }
  .mesa-circular.circle-wrapper .deg-3 .checkbox::before {
    rotate: 66deg;
  }
  .mesa-circular.circle-wrapper .deg-3:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-4 {
    transform: translate(-43px, -15px);
  }
  .mesa-circular.circle-wrapper .deg-4 .checkbox::before {
    rotate: 110deg;
  }
  .mesa-circular.circle-wrapper .deg-4:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-5 {
    transform: translate(-27px, -38px);
  }
  .mesa-circular.circle-wrapper .deg-5 .checkbox::before {
    rotate: 140deg;
  }
  .mesa-circular.circle-wrapper .deg-5:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-6 {
    transform: translate(0px, -48px);
  }
  .mesa-circular.circle-wrapper .deg-6 .checkbox::before {
    rotate: 180deg;
  }
  .mesa-circular.circle-wrapper .deg-6:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-7 {
    transform: translate(27px, -38px);
  }
  .mesa-circular.circle-wrapper .deg-7 .checkbox::before {
    rotate: -140deg;
  }
  .mesa-circular.circle-wrapper .deg-7:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-8 {
    transform: translate(43px, -15px);
  }
  .mesa-circular.circle-wrapper .deg-8 .checkbox::before {
    rotate: -110deg;
  }
  .mesa-circular.circle-wrapper .deg-8:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-9 {
    transform: translate(43px, 15px);
  }
  .mesa-circular.circle-wrapper .deg-9 .checkbox::before {
    rotate: -66deg;
  }
  .mesa-circular.circle-wrapper .deg-9:hover {
    z-index: 10000!important;
  }
  .mesa-circular.circle-wrapper .deg-10 {
    transform: translate(27px, 38px);
  }
  .mesa-circular.circle-wrapper .deg-10 .checkbox::before {
    rotate: -40deg;
  }
  .mesa-circular.circle-wrapper .deg-10:hover {
    z-index: 10000!important;
  }
  .green2 {
    background-color: #00A9A9;
    height: 10px;
    width: 10px;
  }
  .pink {
    background-color: #FF1AB7;
    height: 10px;
    width: 10px;
  }
  .gris2 {
    background-color: #2F2F2F;
    height: 10px;
    width: 10px;
  }