/**=====================
     39. Table  CSS Start
==========================**/
.pills-component {
	width: 20%;
}
.product-page-width {
	width: 80%;
}
.table{
	margin-bottom: $table-b-margin;
	th,td{
		padding: $table-padding;
	}
	th{
		color: $table-heading-color;
	}
	td{
		color: $table-row-color;
	}
	[class*='bg-']{
		color: $white;
	}
	.bg-light{
		color: $theme-body-font-color;
	}
	tfoot{
		font-weight: $table-footer-font-weight;
	}
}
.table-inverse {
	th,td{
		color: $white;
	}
}
.table[class*='bg-']{
	th,td{
		color: $white;
	}
}
.table-hover {
	tbody {
		tr:hover {
			background-color: $table-hover-color;
		}
	}
}

//Sizing class
.table-xl{
	td,th{
		padding: $table-xl-padding;
		font-size: $table-xl-font;
	}
}
.table-lg{
	td,th{
		padding: $table-lg-padding;
		font-size: $table-lg-font;
	}
}
.table-de{
	td,th{
		padding: $table-de-padding;
	}
}
.table-sm{
	th,td{
		padding: $table-sm-padding;
		font-size: $table-sm-font;
	}
}
.table-xs{
	th,td{
		padding: $table-xs-padding;
		font-size: $table-xs-font;
	}
}

//Table Border
.table-border-horizontal {
	tr,th,td{
		border-top: 1px solid $horizontal-border-color;
		padding: $horizontal-padding;
	}
}
.table-border-vertical{
	tr,th,td{
		border-top: 0;
		border-right: 1px solid $horizontal-border-color;
	}
	tr{
		td{
			&:last-child{
				border-right: none;
			}
		}
	}
}

.table-bordernone{
	td{
		border: 0;
	}
	.bd-t-none{
		border-top: none;
	}
	.u-s-tb{
		padding: 11px;
	}
}
.table-inverse{
	color: $white;
	background-color: $table-inverse-bg-color;
	th,td{
		border-color: $white;
	}
	&.table-bordered {
		border: 0;
	}
}

.table-striped{
	tbody{
		tr{
			&:nth-of-type(odd){
				background-color: rgba(245, 247, 250, 0.5);
			}
		}
	}
}
.table-bordered{
	td,th{
		border-color: $light;
	}
}
/**=====================
     39.Table CSS Ends
==========================**/
#oce{
	table-layout: fixed;
    width: 1558px;
	th{
		&:nth-child(1){
			width: 160px;
		}
		&:nth-child(2){
			width: 205px;
		}
		&:nth-child(3){
			width: 190px;
		}
		&:nth-child(4){
			width: 230px;
		}
		&:nth-child(5),&:nth-child(6),&:nth-child(7) {
			width: 227px;
		}
	}
}

.fix-lay{
	table-layout: fixed;
}

th:nth-child(1){
	width: 12%;
}
th:nth-child(2){
	width: 12%;
}
th:nth-child(3){
	width: 14%;
}
th:nth-child(4){
	width: 16%;
}
th:nth-child(4){
	width: 12%;
}
th:nth-child(5){
	width: 18%;
}
th:nth-child(6){
	width: 18%;
}
th:last-child{
	width: 10%;
}

.table-responsive{
	min-height: 320px; 
}

  


  @media screen and (max-width:1120px){
	.table-responsive{
		&.prueba{

				overflow:auto;
			th{
				border-bottom: none !important;
			}
		
			td{
				border-top: none !important;
			}
		
		
			table {
				table-layout: fixed;
				/* width:100%; */
			}
			td:last-child, th:last-child {
				position:sticky;
				right:0;
				z-index:1;
				background-color: #e6eff3;
			}
			
			th:nth-child(1){
				width: 200px;
			}
			th:nth-child(2){
				width: 234px;
			}
			th:nth-child(3){
				width: 233px;
			}
			th:nth-child(4){
				width: 210px;
			}
			th:nth-child(4){
				width: 200px;
			}
			th:nth-child(5){
				width: 200px;
			}
			th:nth-child(6){
				width: 200px;
			}
			th:last-child{
				width: 150px;
			}
			th:last-child {
				z-index:2;
				box-shadow: -5px 0px 24px 0 rgba(68, 102, 242,  35%);
				background-color: #e6eff3;
				position:sticky;
				top:0;
			}
		
	
		}
	  }
  }


  .filter-btn{
	display: inline-flex;
    padding: 10px;
    text-align: center;
    background: $primary-color;
    font-size: 13px;
    border-radius: 6px;
    color: #ffffff;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 5px;
	cursor: pointer;
  }
  
  .table-sticky{
	  th, td, tr{
		  border-bottom: none !important;
		  border-top: none !important;
		  border: none !important;
	  }
  }

  .tab-header{
	display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }